import React from "react";

import ContactButton from "./components/ContactButton";
import NavigationMenu from "./components/NavigationMenu";

const Navigation = ({ openDrawerHandler }) => {
  return (
    <div className="flex items-center  gap-x-10 text-white font-nato py-3 dont-show-on-desktop">
      {/* <ContactButton /> */}
      
      <NavigationMenu openDrawerHandler={openDrawerHandler} />
    </div>
  );
};

export default Navigation;
