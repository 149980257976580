import React from "react";
import { Link } from "react-router-dom";

const Button = ({ children, isBlack, isBig, textIsWhite, to }) => {
  return (
    <Link
      to={to}
      className={`border border-secondary px-8 font-nato capitalize  ${
        isBig ? "py-3" : "py-1"
      } rounded-md w-max ${isBlack ? "bg-black" : ""} ${
        textIsWhite ? "text-white" : ""
      }`}
    >
      {children}
    </Link>
  );
};

export default Button;
