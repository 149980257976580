import Container from "Components/Container/Container";
import React from "react";
import FooterLogo from "./components/FooterLogo";
import FooterNav from "./components/FooterNav";
import FooterServices from "./components/FooterServices.jsx";
import FooterContact from "./components/FooterContact";
const Footer = () => {
  return (
    <footer className="pt-secondary bg-primary relative z-[1000]">
      <Container>
        <div className="border border-secondary px-12 pt-12 rounded-tl-2xl rounded-tr-2xl  relative">
          <div className="bg-white  opacity-[6%] absolute top-0 left-0 w-full h-full z-[1]"></div>

          <div className="mt-16 relative z-[10] grid grid-cols-1 lg:grid-cols-4 gap-10">
            <FooterLogo />
            <FooterNav />
            <FooterServices />
            <FooterContact />
          </div>

          <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between mt-20 pb-6 text-[#ddd] relative z-[100000]">
            <p>All Rights Reserved NABS 2024</p>
            <p className=" ">
              Powered by{" "}
              <a
                className="hover:underline"
                rel="noreferrer"
                target="_blank"
                href="https://www.dowgroup.com/"
              >
                Dow Group
              </a>{" "}
            </p>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
