import React, { useState } from "react";
import Container from "Components/Container/Container";
import Input from "form/Inputs/Input";
import useInput from "form/Hooks/user-input";
import Textarea from "form/Inputs/Textarea";
import ReCAPTCHA from "react-google-recaptcha";
import Spinner from "Components/RequestHandler/Spinner";

const Form = () => {
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [formStatus, setFormStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onCaptchaChange = (value) => {
    setCaptchaVerified(!!value); // If value exists, captcha is verified
  };

  const {
    value: fullNameInput,
    isValid: fullNameIsValid,
    HasError: fullNameHasError,
    inputChangeHandler: fullNameChangeHandler,
    inputBlurHandler: fullNameBlurHanlder,
    reset: fullNameReset,
  } = useInput((value) => /^[a-zA-Z]+\s[a-zA-Z]+$/.test(value));

  const {
    value: emailInput,
    isValid: emailIsValid,
    HasError: emailHasError,
    inputChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHanlder,
    reset: emailReset,
  } = useInput((value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value));

  const {
    value: companyInput,
    inputChangeHandler: companyChangeHandler,
    inputBlurHandler: companyBlurHanlder,
    reset: companyReset,
  } = useInput((value) => /^[a-zA-Z\s]*$/.test(value)); // Optional

  const {
    value: subjectInput,
    inputChangeHandler: subjectChangeHandler,
    inputBlurHandler: subjectBlurHanlder,
    reset: subjectReset,
  } = useInput((value) => /^[a-zA-Z\s]*$/.test(value)); // Optional

  const {
    value: messageInput,
    isValid: messageIsValid,
    HasError: messageHasError,
    inputChangeHandler: messageChangeHandler,
    inputBlurHandler: messageBlurHanlder,
    reset: messageReset,
  } = useInput((value) => value.trim().length !== 0);

  const clearInputs = () => {
    fullNameReset();
    emailReset();
    companyReset();
    subjectReset();
    messageReset();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (fullNameIsValid && emailIsValid && messageIsValid && captchaVerified) {
      const formData = {
        full_name: fullNameInput,
        email: emailInput,
        company: companyInput,
        subject: subjectInput,
        message: messageInput,
      };

      try {
        setIsLoading(true);
        const response = await fetch(
          "https://www.nabs-its.com/contact-us-form",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        if (response.ok) {
          setFormStatus("success");
          clearInputs(); // Clear inputs on success
        } else {
          setFormStatus("failed");
        }
      } catch (error) {
        setFormStatus("failed");
      } finally {
        setIsLoading(false);
      }

      // Set a timeout to clear the form status after 5 seconds
      setTimeout(() => {
        setFormStatus(null);
      }, 5000);
    } else {
      setFormStatus("failed");
      setTimeout(() => {
        setFormStatus(null);
      }, 5000);
    }
  };

  return (
    <div className="pt-32 lg:pt-primary">
      <Container>
        <div className="">
          <h6 className="text-secondary text-2xl">Contact us</h6>
        </div>

        <div className="bg-white px-8 py-8 lg:px-24 lg:py-12 rounded-[32px] mt-secondary">
          <h4 className="font-black text-3xl text-primary text-center">
            Contact Form
          </h4>

          <p
            className={`text-center mt-2  ${
              formStatus === "success" ? "text-green-500" : "text-red-500"
            }`}
          >
            {formStatus === "success"
              ? "Your message has been successfully sent."
              : formStatus === "failed"
              ? "There was an error sending your message. Please try again."
              : ""}
          </p>

          <form className="mt-10 lg:mt-20" onSubmit={handleSubmit}>
            <span className="flex flex-col lg:flex-row items-center gap-x-12">
              <Input
                type="text"
                label="Full Name"
                id="register-full-name"
                value={fullNameInput}
                onChange={fullNameChangeHandler}
                onBlur={fullNameBlurHanlder}
                hasError={fullNameHasError}
                errorMessage=""
              />
              <Input
                type="email"
                label="Email"
                id="register-email"
                value={emailInput}
                onChange={emailChangeHandler}
                onBlur={emailBlurHanlder}
                hasError={emailHasError}
                errorMessage=""
              />
            </span>
            <span className="flex flex-col lg:flex-row items-center gap-x-12 lg:my-8">
              <Input
                type="text"
                label="Company"
                id="company"
                value={companyInput}
                onChange={companyChangeHandler}
                onBlur={companyBlurHanlder}
                hasError=""
                errorMessage=""
              />
              <Input
                type="text"
                label="Subject"
                id="subject"
                value={subjectInput}
                onChange={subjectChangeHandler}
                onBlur={subjectBlurHanlder}
                hasError=""
                errorMessage=""
              />
            </span>

            <Textarea
              type="text"
              label="Your Message"
              id="message"
              value={messageInput}
              onChange={messageChangeHandler}
              onBlur={messageBlurHanlder}
              hasError={messageHasError}
              errorMessage=""
            />

            <div className="mt-8">
              {/* Add reCAPTCHA component */}
              <ReCAPTCHA
                sitekey="6Lchu04qAAAAAKUynlfeXyLv_3ksiHCCHNcPjEe1" // Replace with your actual site key
                onChange={onCaptchaChange}
              />
            </div>

            <button
              type="submit"
              className={`bg-primary uppercase text-white px-8 text-sm mt-10 mx-auto w-max flex items-center gap-x-2 justify-center py-2 ${
                !captchaVerified ? "opacity-50 cursor-not-allowed" : ""
              }`}
              // disabled={!captchaVerified}
            >
              {isLoading && <Spinner isWhite={true} />}
              Submit
            </button>
          </form>
        </div>
      </Container>
    </div>
  );
};

export default Form;
