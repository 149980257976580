import Container from "Components/Container/Container";
import { useEffect, useState } from "react";
import useFetch from "Hooks/useFetch";

const ContactInfo = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { fetchData } = useFetch();

  const getData = async () => {
    setIsLoading(true);
    setIsError(false);

    try {
      const data = await fetchData("api/contact-us");
      setData(data?.contact_details);
    } catch (error) {
      setIsError(true);
      // console.error("Failed to fetch home data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <section className="mt-secondary">
      <Container>
        <div className="mb-10 lg:mb-20">
          <h4 className="text-3xl font-black lg:text-center text-white">
            Contact Info
          </h4>
        </div>

        <div className="flex flex-col lg:flex-row gap-y-12 gap-x-24 text-white bg-dark px-6 py-12 rounded-xl">
          {data?.map(({ city, location, box, phone, fax, email }, index) => (
            <div
              className="flex-1 flex flex-col justify-center  gap-y-5"
              key={index}
            >
              <p className="text-3xl font-medium">{city}</p>
              <p className="w-3/4">{location}</p>
              <a target="_blank" rel="noreferrer" href={`mailto:${email}`}>
                Email: {email}
              </a>
              <a target="_blank" rel="noreferrer" href={`tel:${phone}`}>
                Phone: {phone}
              </a>
              <a target="_blank" rel="noreferrer" href={`fax:${fax}`}>
                Fax: {fax}
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://www.google.com/search?q=PO.Box:${box}`}
              >
                PO.Box: {box}
              </a>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default ContactInfo;
