import Container from "Components/Container/Container";
import Button from "Components/UI/Button";
import Counter from "Components/UI/Counter";
import stripHtmlTags from "Utilities/stripHtmlTags";

const WhyUs = ({ data }) => {
  return (
    <section id="why-us"> {/* Added ID */}
      <Container>
        <div className="flex relative z-[100] flex-col lg:flex-row  lg:items-center lg:justify-between font-nato text-white gap-y-6 gap-x-24">
          <div className="flex-1">
            <img src={data?.image} alt="" className="lg:w-3/4 lg:ml-auto" />
          </div>
          <div className="flex-1 ">
            <h4 className="text-4xl font-[900]">{data?.title}</h4>
            <p className="font-[300] text-lightFont text-sm w-3/4 mt-4">
              {stripHtmlTags(data?.text)}
            </p>

            {/* Statistics with Counters */}
            <div className="mt-6 flex flex-col md:flex-row gap-2">
              <div className="flex flex-col items-center gap-y-2 p-4 border border-gray-400 rounded-lg">
                <Counter endValue={150} duration={2000} sectionId="why-us" /> {/* Counter for 150+ */}
                <p className="text-base text-center">In House Team</p>
              </div>
              <div className="flex flex-col items-center gap-y-2 p-4 border border-gray-400 rounded-lg">
                <Counter endValue={25} duration={2000} sectionId="why-us" /> {/* Counter for 25+ */}
                <p className="text-base text-center">Years of Experience</p>
              </div>
              <div className="flex flex-col items-center gap-y-2 p-4 border border-gray-400 rounded-lg">
                <Counter endValue={1000} duration={2000} sectionId="why-us" /> {/* Counter for 1000+ */}
                <p className="text-base text-center">Completed Projects</p>
              </div>
            </div>

            <div className="mt-10">
              <Button to={"/about-us"} isBig={true} isBlack={true}>
                Learn More
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default WhyUs;
