import { useEffect, useState } from "react";
import bg from "assests/clients.jpg";
import IsLoading from "Components/RequestHandler/IsLoading";
import IsError from "Components/RequestHandler/IsError";
import useFetch from "Hooks/useFetch";
import Container from "Components/Container/Container";
import { Link } from "react-router-dom";
const Blog = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { fetchData } = useFetch();

  const getData = async () => {
    setIsLoading(true);
    setIsError(false);

    try {
      const data = await fetchData("api/blogs");
      setData(data?.blogdata);
    } catch (error) {
      setIsError(true);
      // console.error("Failed to fetch home data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (isLoading) return <IsLoading />;
  if (isError) return <IsError />;
  if (data) {
    return (
      <main>
        <div
          className="fixed top-0 left-0 w-full h-full bg-cover bg-center z-0"
          style={{ backgroundImage: `url(${bg})` }}
        ></div>
        <section className="relative z-10 pt-[20vh]">
          <Container>
            <div>
              <h1 className="text-4xl lg:text-5xl font-black text-white">
                Blogs
              </h1>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-14 text-white mt-14 mb-primary">
              {data.map(({ slug, image, title }, index) => (
                <div
                  key={index}
                  className=" flex items-center justify-center  rounded-xl overflow-hidden  "
                >
                  <Link to={`/blog/${slug}`}>
                    <div className="overflow-hidden rounded-xl transition ease-in duration-300 border border-transparent hover:border-secondary min-h-[350px]  max-h-[350px] h-[350px]">
                      <img
                        className="transition min-h-[350px]  max-h-[350px] h-[350px] w-full ease-in duration-300 hover:scale-[1.05] "
                        src={image}
                        alt=""
                      />
                    </div>

                    <p className="text-xl font-[500] mt-4">{title}</p>

                    <span className="text-sm mt-4">read more</span>
                  </Link>
                </div>
              ))}
            </div>
          </Container>
        </section>
      </main>
    );
  }
};

export default Blog;
