import { ClientsProvider } from "./ClientsContext";
import { BlogProvider } from "./BlogContext";
import { PartnerProvider } from "./PartnerContext";
import { ServicesProvider } from "./ServicesContext";
const ContextProvider = ({ children }) => {
  return (
    <ClientsProvider>
      <BlogProvider>
        <PartnerProvider>
          <ServicesProvider>{children}</ServicesProvider>
        </PartnerProvider>
      </BlogProvider>
    </ClientsProvider>
  );
};

export default ContextProvider;
