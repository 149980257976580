import Container from "Components/Container/Container";
import PartnerSlider from "./PartnerSlider";
import { Link } from "react-router-dom";

const Partner = () => {
  return (
    <section>
      <Container>
        <div className="font-nato text-center">
          <h2 className="text-secondary text-3xl font-[300]">
            Strategic Partnerships
          </h2>
        </div>

        <PartnerSlider />
        <Link
          className="text-center  text-white block mt-2 hover:underline"
          to={"/partner"}
        >
          View All
        </Link>
      </Container>
    </section>
  );
};

export default Partner;
