import Container from "Components/Container/Container";
import React from "react";
import bg from "assests/about/aw1.png";

const Award = () => {
  return (
    <section className="hidden lg:block py-secondary">
      <Container>
        <div className=" text-white  h-[350px] relative  flex items-center justify-center w-3/4 mx-auto">
          <div className="relative z-[1]">
            <img className="w-full h-full" src={bg} alt="" />
          </div>
          <span className="absolute text-white z-[1000] right-28 top-1/2 translate-y-1/4 flex items-center gap-x-8  justify-end ">
            <p className="font-black text-6xl">100+</p>
            <p className="text-2xl">
              Awards & <br /> Recognition Certificates
            </p>
          </span>
        </div>
      </Container>
    </section>
  );
};

export default Award;
