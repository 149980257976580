import React, { useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// UI
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import { Autoplay, Pagination, EffectFade } from "swiper/modules";

import "./hero.css";

import Container from "Components/Container/Container";
import Button from "Components/UI/Button";

const Hero = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [isImageLoading, setIsImageLoading] = useState(true);

  // Custom event listener to track logical active index
  useEffect(() => {
    const handleSlideChange = () => {
      setActiveIndex(swiperInstance.realIndex);
    };

    if (swiperInstance) {
      swiperInstance.on("slideChange", handleSlideChange);
    }

    return () => {
      if (swiperInstance) {
        swiperInstance.off("slideChange", handleSlideChange);
      }
    };
  }, [swiperInstance]);

  const loadingImage = () => {
    setIsImageLoading(false);
  };

  return (
    <section className="w-full hero-section overflow-hidden relative  h-[100vh] ">
      <Swiper
        spaceBetween={0}
        effect={"fade"}
        centeredSlides={true}
        speed={1000}
        loop={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination, EffectFade]}
        onSwiper={setSwiperInstance}
        className="heroSlider"
      >
        {data?.images?.map((img, indx) => (
          <SwiperSlide key={indx} className="">
            <div
              className={`image-parent relative zoom ${
                activeIndex === indx ? "zoomed" : ""
              }`}
            >
              <img
                src={img}
                onLoad={loadingImage}
                className={`hero-image ${isImageLoading ? "hidden" : "block"}`}
                alt=""
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="absolute left-0 top-1/2 -translate-y-1/2 z-[100]">
        <Container className={"h-full w-full"}>
          <div className="text-white font-nato flex  flex-col h-full w-full  relative z-[10] ">
            <h1 className="font-[400] text-secondary text-2xl lg:text-3xl whitespace-nowrap">
              Simplifying Technology
            </h1>
            <p className="text-4xl ss:text-5xl lg:text-7xl font-[900] mb-10">
              Empowering <br /> Transformation
            </p>

            <Button to={"/about-us"} isBig={true} isBlack={true}>
              read more
            </Button>
          </div>
        </Container>
      </div>
    </section>
  );
};

export default Hero;
