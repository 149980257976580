import React, { useState, useEffect } from "react";
import Container from "Components/Container/Container";
import bannerImage from "assests/hero/bsmall-2.jpg";
import bg from "assests/hero/aw1.png";

import "./Banner.css"; // Add this for additional CSS if needed

const Banner = () => {
  const [showHoverImage, setShowHoverImage] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowHoverImage((prevState) => !prevState); // Toggle between images
    }, 2000); // 2-second interval

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  return (
    <section className="pt-secondary hidden lg:block">
      <Container>
        <div className="relative mx-auto rounded-[32px] z-[10] border border-secondary font-nato text-white overflow-hidden lg:w-[75%] banner-wrapper">
          {/* Default image */}
          <img
            src={bannerImage}
            alt="Banner"
            className={`banner-image ${showHoverImage ? "hidden" : "block"}`}
          />
          {/* Hover image */}
          <div className="relative z-[1]">
            <img
              src={bg}
              alt="Banner Hover"
              className={`banner-image-hover  ${
                showHoverImage ? "block" : "hidden"
              }`}
            />
            <span className="absolute text-white z-[1000] right-20 top-10 flex gap-x-2">
              <p className="font-black text-4xl">100+</p>
              <p className="text-3xl">
                Awards & <br /> Recognition Certificates
              </p>
            </span>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Banner;
