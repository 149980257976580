import React from "react";
import { footerLinkNav } from "Constant/FooterContent";
import { Link } from "react-router-dom";
const FooterNav = () => {
  return (
    <div className="flex flex-col gap-2 font-nato text-secondary">
      {footerLinkNav?.map(({ link, text }, index) => (
        <Link className="hover:underline uppercase" key={index} to={link}>
          {text}
        </Link>
      ))}
    </div>
  );
};

export default FooterNav;
