import { createContext, useState, useEffect } from "react";
import useFetch from "Hooks/useFetch";

export const ClientsContext = createContext();

export const ClientsProvider = ({ children }) => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { fetchData } = useFetch();

  const getData = async () => {
    setIsLoading(true);
    setIsError(false);

    try {
      const data = await fetchData("api/clients");
      setData(data?.clients_data);
    } catch (error) {
      setIsError(true);
      // console.error("Failed to fetch home data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <ClientsContext.Provider
      value={{
        isLoading,
        isError,
        data,
      }}
    >
      {children}
    </ClientsContext.Provider>
  );
};
