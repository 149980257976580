import React from "react";
import Container from "Components/Container/Container";
import stripHtmlTags from "Utilities/stripHtmlTags";

import "./style.css";
const Features = ({ data }) => {
  return (
    <section className="bg-primary py-secondary ">
      <Container>
        <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 justify-center gap-6 lg:gap-10">
          {data?.map(({ icon, text }, index) => (
            <div
              className="bg-dark flex items-center justift-center text-center flex-col py-8 px-16 md:px-4 md:py-8 lg:py-8 lg:px-14 rounded-2xl text-white gap-y-4 feature-card"
              key={index}
            >
              <img className="w-12 mx-auto" src={icon} alt="" />
              {/* <p className=" "> {stripHtmlTags(text)}</p> */}

              <p
                className="leading-relaxed"
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default Features;
