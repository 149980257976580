import { useState, useEffect } from "react";
import IsLoading from "Components/RequestHandler/IsLoading";
import IsError from "Components/RequestHandler/IsError";
import useFetch from "Hooks/useFetch";

import Hero from "./Hero/Hero";
import WhoWeAre from "./WhoWeAre/WhoWeAre";
import Features from "./Features/Features";
import Values from "./Values/Values";
import Award from "./Award/Award";
import Clients from "Pages/Home/Clients/Clients";
import Blog from "Pages/Home/Blog/Blog";

const About = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { fetchData } = useFetch();

  const getData = async () => {
    setIsLoading(true);
    setIsError(false);

    try {
      const data = await fetchData("api/about-us");
      setData(data?.about_data);
    } catch (error) {
      setIsError(true);
      // console.error("Failed to fetch home data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (isLoading) return <IsLoading />;
  if (isError) return <IsError />;
  if (data) {
    return (
      <main>
        <Hero data={data?.hero} />
        <WhoWeAre data={data?.who_we_are} />
        <Features data={data?.features} />
        <Values data={data?.values} />
        <Award />
        <Clients data={data?.clients} />
        <Blog />
      </main>
    );
  }
};

export default About;
