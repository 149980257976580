// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "./style.css";
import { Link } from "react-router-dom";
import { useContext } from "react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation, Keyboard } from "swiper/modules";

import Container from "Components/Container/Container";

import { BlogContext } from "Context/BlogContext";

const Blog = () => {
  const { data } = useContext(BlogContext);
  return (
    <section className="font-nato text-white py-secondary bg-primary z-[1000] relative">
      <Container>
        <h1 className="font-[600] text-4xl">Blog</h1>

        <Swiper
          slidesPerView={1}
          slidesPerGroup={1}
          breakpoints={{
            768: {
              slidesPerView: 2,
              slidesPerGroup: 2,
            },
            992: {
              slidesPerView: 3,
              slidesPerGroup: 3,
            },
          }}
          spaceBetween={80}
          pagination={{
            clickable: true,
          }}
          keyboard={{
            enabled: true,
          }}
          speed={1000}
          navigation={true}
          modules={[Keyboard, Pagination, Navigation]}
          className="mt-6 blog-slider"
        >
          {data?.map(({ image, title, button, slug }, index) => (
            <SwiperSlide
              key={index}
              className=" flex items-center justify-center  rounded-xl overflow-hidden  "
            >
              <Link to={`/blog/${slug}`}>
                <div className="overflow-hidden rounded-xl transition ease-in duration-300 border border-transparent hover:border-secondary">
                  <img
                    className="transition h-[350px] w-full ease-in duration-300 hover:scale-[1.05] "
                    src={image}
                    alt=""
                  />
                </div>

                <p className="text-xl font-[500] mt-4">{title}</p>

                <span className="text-sm mt-4">read more</span>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>

        <Link className="text-center block mt-20 hover:underline " to={"/blog"}>
          View All
        </Link>
      </Container>
    </section>
  );
};

export default Blog;
