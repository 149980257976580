import React, { useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import stripHtmlTags from "Utilities/stripHtmlTags";

// UI
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import { Autoplay, Pagination, EffectFade } from "swiper/modules";

import "./hero.css";

import Container from "Components/Container/Container";
import Button from "Components/UI/Button";

const Hero = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [isImageLoading, setIsImageLoading] = useState(true);

  // Custom event listener to track logical active index
  useEffect(() => {
    const handleSlideChange = () => {
      setActiveIndex(swiperInstance.realIndex);
    };

    if (swiperInstance) {
      swiperInstance.on("slideChange", handleSlideChange);
    }

    return () => {
      if (swiperInstance) {
        swiperInstance.off("slideChange", handleSlideChange);
      }
    };
  }, [swiperInstance]);

  const loadingImage = () => {
    setIsImageLoading(false);
  };

  return (
    <section className="w-full hero-section-about overflow-hidden relative ">
      <div className={`image-parent-about relative zoom zoomed `}>
        <img
          src={data?.image}
          onLoad={loadingImage}
          className={`hero-image-about min-h-[600px]  ${
            isImageLoading ? "hidden" : "block"
          }`}
          alt=""
        />
      </div>

      <div className="absolute left-0 top-1/2 -translate-y-1/2 z-[100]">
        <Container className={"h-full"}>
          <div className="text-white font-nato flex  flex-col h-full w-full  relative z-[1000] mt-secondary lg:mt-0 lg:min-w-[max-content] ">
            {/* <h1 className="font-[300] text-secondary text-2xl  ss:text-3xl mb-2 w-[90%] ">
              {data?.sub_title}
            </h1> */}

            <h1
              className="text-4xl ss:text-5xl sm:text-6xl font-[900] mb-10"
              dangerouslySetInnerHTML={{ __html: data?.title }}
            />
          </div>
        </Container>
      </div>
    </section>
  );
};

export default Hero;
