import React from "react";

import Form from "./Components/Form";
import ContactInfo from "./Components/ContactInfo";
const Contact = () => {
  return (
    <main>
      <Form />
      <ContactInfo />
    </main>
  );
};

export default Contact;
