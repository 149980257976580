import { useContext } from "react";
import { footerServices } from "Constant/FooterContent";
import { Link } from "react-router-dom";
import { ServicesContext } from "Context/ServicesContext";

const FooterServices = () => {
  const { data, isLoading } = useContext(ServicesContext);
  return (
    <div className="font-nato">
      <p className="uppercase text-secondary mb-4">{footerServices.title}</p>
      <div className="flex flex-col gap-2  ">
        {data?.map(({ slug, title }, index) => (
          <Link
            className="hover:underline text-white "
            key={index}
            to={`/services/${slug}`}
          >
            {title}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default FooterServices;
