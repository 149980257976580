import partnerBg from "assests/partner/bg-partners.jpg";
import Container from "Components/Container/Container";
import Clients from "Pages/Home/Clients/Clients";
import { useEffect, useState } from "react";

import IsLoading from "Components/RequestHandler/IsLoading";
import IsError from "Components/RequestHandler/IsError";
import useFetch from "Hooks/useFetch";

const Partner = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { fetchData } = useFetch();

  const getData = async () => {
    setIsLoading(true);
    setIsError(false);

    try {
      const data = await fetchData("api/partners");
      setData(data);
    } catch (error) {
      setIsError(true);
      // console.error("Failed to fetch home data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (isLoading) return <IsLoading />;
  if (isError) return <IsError />;
  if (data) {
    return (
      <div>
        <main className="relative min-h-screen">
          <section
            className="fixed top-0 left-0 w-full h-full bg-cover bg-center z-0"
            style={{ backgroundImage: `url(${partnerBg})` }}
          ></section>

          <div className="relative z-10 pt-[20vh]">
            <Container>
              <div className="mb-secondary">
                {/* <h6 className="text-secondary mb-2 text-xl">Partners</h6> */}
                <p className="font-black text-4xl lg:text-5xl text-white">
                  Our Partnerships
                </p>
              </div>
              <div className="grid grid-cols-2 lg:grid-cols-4 md:grid-cols-3  gap-3 lg:gap-6 mb-primary">
                {data?.partners_logos?.map((image, index) => (
                  <div
                    className="bg-white p-2 rounded-xl flex items-center justify-center"
                    key={index}
                  >
                    <img className=" rounded-xl" src={image} alt="" />
                  </div>
                ))}
              </div>
            </Container>
          </div>
        </main>
        <Clients data={data?.clients} />
      </div>
    );
  }
};

export default Partner;
