import logo from "assests/logo.png";
import { Link } from "react-router-dom";

const FooterLogo = () => {
  return (
    <Link to={"/"}>
      <img className="w-60" src={logo} alt="" />
    </Link>
  );
};

export default FooterLogo;
