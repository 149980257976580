import { useEffect, useState } from "react";

import IsLoading from "Components/RequestHandler/IsLoading";
import IsError from "Components/RequestHandler/IsError";

// inner components
import Hero from "./Hero/Hero";
import Partner from "./Partner/Partner";
import WhyUs from "./WhyUs/WhyUs";
import ServicesSlider from "./Services/ServicesSlider";
import Clients from "./Clients/Clients";
import Banner from "./Banner/Banner";
import Blog from "./Blog/Blog";
import useFetch from "Hooks/useFetch";

const HomePage = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { fetchData } = useFetch();
  const getHomeData = async () => {
    setIsLoading(true);
    setIsError(false);

    try {
      const homeData = await fetchData("api/home");
      setData(homeData?.homeData);
    } catch (error) {
      setIsError(true);
      // console.error("Failed to fetch home data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getHomeData();
  }, []);

  if (isLoading) return <IsLoading />;
  if (isError) return <IsError />;
  if (data) {
    return (
      <main>
        <Hero data={data?.hero} />
        <div className="py-primary flex flex-col gap-y-primary parallax home-bg">
          <Partner />
          <WhyUs data={data?.why_us} />
        </div>
        <ServicesSlider />
        <Clients data={data?.clients} />
        <Banner />
        <Blog />
      </main>
    );
  }
};

export default HomePage;
