import Container from "Components/Container/Container";
import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { CaretRight } from "@phosphor-icons/react";
import useFetch from "Hooks/useFetch";
import IsLoading from "Components/RequestHandler/IsLoading";
import IsError from "Components/RequestHandler/IsError";

const InnerServices = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { fetchData } = useFetch();

  const getData = async () => {
    setIsLoading(true);
    setIsError(false);

    try {
      const data = await fetchData(`api/inner-sub-services/${slug}`);
      setData(data?.data);
    } catch (error) {
      setIsError(true);
      console.error("Failed to fetch home data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [slug]);

  const goBack = () => {
    navigate(-1);
  };

  if (isLoading) return <IsLoading />;
  if (isError) return <IsError />;
  if (data) {
    return (
      <main>
        <div className="relative z-10 pt-[20vh]">
          <Container>
            <section className="mb-secondary">
              <div className="">
                <h1 className="font-[300] text-secondary text-xl   flex flex-wrap items-center min-w-[min-content]">
                  Services{" "}
                  <span className="mt-[2px]">
                    <CaretRight />
                  </span>{" "}
                  <Link
                    className="tranistion ease-in duration-300 hover:underline"
                    onClick={goBack}
                  >
                    {data?.parentSubServiceName}
                  </Link>
                  <span className="mt-[2px]">
                    <CaretRight />
                  </span>{" "}
                  {data?.innerSubServiceName}
                </h1>
              </div>
            </section>

            <section className="bg-white px-4 py-10 lg:p-8 rounded-[44px]">
              <div className="flex flex-col gap-y-10">
                {data?.content.map(({ title, text, list }, index) => (
                  <div key={index}>
                    <div>
                      <h2 className="rb-semibold text-3xl mb-2 text-gray-400">
                        {title}
                      </h2>
                      <p className="text-gray-600">{text}</p>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
                      {list?.map(({ title, text }, index) => (
                        <div
                          className="bg-gray-100 p-3 rounded-lg border border-primary grid grid-cols-1 grid-rows-3"
                          key={index}
                        >
                          <h4 className="font-semibold mb-3 text-2xl text-primary">
                            {title}
                          </h4>
                          {/* <p className="row-span-2 font-semibold text-gray-700 text-sm">
                          {text}
                        </p> */}

                          <p
                            className="row-span-2 font-semibold text-gray-700 text-sm"
                            dangerouslySetInnerHTML={{ __html: text }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </Container>
          {/* <InnerSubServices /> */}
        </div>
      </main>
    );
  }
};

export default InnerServices;
