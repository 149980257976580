import React, { useState, useEffect, useContext } from "react";
import Container from "Components/Container/Container";
import Logo from "./Components/Logo";
import Navigation from "./Navigation/Navigation";
import { X, LinkedinLogo, CaretDown, CaretUp } from "@phosphor-icons/react";
import { Link } from "react-router-dom";
import { ServicesContext } from "Context/ServicesContext";
const Header = () => {
  const [drawerIsActive, setDrawerIsActive] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const { data, isLoading } = useContext(ServicesContext);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const openDrawerHandler = () => {
    setDrawerIsActive(true);
  };

  const closeDrawerHandler = () => {
    setDrawerIsActive(false);
  };

  const [showServices, setShowServices] = useState(false); // State to track whether to show services data

  const toggleServices = () => {
    setShowServices(!showServices); // Toggle the state when "Services" is clicked
  };

  return (
    <header
      className={`fixed top-0 left-0 w-full z-[10000] transition ease-in duration-300 ${
        isSticky ? "bg-[#1f75abf2]" : "bg-transparent"
      }`}
    >
      <Container>
        <div className="flex items-center justify-between py-4">
          <Logo />
          <ul className="flex flex-row gap-x-6 capitalize dont-show-on-mobile">
            <Link
              to={"/about-us"}
              onClick={closeDrawerHandler}
              className=" py-2 text-white font-medium"
            >
              About us
            </Link>
            <div className="relative group py-2 text-white font-medium">
              <span className="flex items-center justify-between capitalize cursor-pointer">
                services
                <CaretDown size={20} />
              </span>
              <ul className="absolute left-0 mt-2 w-[max-content] flex-col  py-4 px-1 items-start text-sm capitalize list-none bg-[#1f75ab] rounded-md hidden group-hover:flex">
                {data?.map(({ slug, title }, index) => (
                  <li key={index}>
                    <Link
                      to={`/services/${slug}`}
                      onClick={closeDrawerHandler}
                      className="text-white text-start hover:underline px-4 py-3 block"
                    >
                      {title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <Link
              to={"/partner"}
              onClick={closeDrawerHandler}
              className=" py-2 text-white font-medium"
            >
              Partners
            </Link>
            <Link
              to={"/clients"}
              onClick={closeDrawerHandler}
              className=" py-2 text-white font-medium"
            >
              clients
            </Link>
            <Link
              to={"/awards"}
              onClick={closeDrawerHandler}
              className="py-2 text-white font-medium"
            >
              awards
            </Link>
            <Link
              to={"/contact-us"}
              onClick={closeDrawerHandler}
              className="py-2 text-white font-medium"
            >
              Contact us
            </Link>
          </ul>
          <Navigation openDrawerHandler={openDrawerHandler} />
        </div>
      </Container>

      <div
        className={`fixed right-0 top-0 bottom-0 w-[95vw] ss:w-[80vw] md:w-[46vw] lg:w-[38vw] xl:w-[26rem] h-[100vh] bg-[#163664b5] z-[1000] px-6 py-10 flex flex-col justify-between transition ease-in duration-300 ${
          drawerIsActive ? "translate-x-[0]" : "translate-x-[100%]"
        }`}
      >
        <div>
          <div className="flex flex-col gap-y-3 flex-col-reverse ss:flex-row items-center justify-between">
            <Logo isSmall={true} />

            <button onClick={closeDrawerHandler}>
              <X size={28} color={"white"} />
            </button>
          </div>
          <div>
            <ul className="flex flex-col gap-y-2 capitalize mt-14">
              {/* <Link
                to={"/"}
                onClick={closeDrawerHandler}
                className="border-b py-2 text-white font-medium"
              >
                Home
              </Link> */}
              <Link
                to={"/about-us"}
                onClick={closeDrawerHandler}
                className="border-b py-2 text-white font-medium"
              >
                About us
              </Link>
              <button
                to={"/services"}
                onClick={toggleServices}
                className="border-b py-2 text-white font-medium "
              >
                <span className="flex items-center justify-between capitalize">
                  services
                  {showServices ? (
                    <CaretUp size={20} />
                  ) : (
                    <CaretDown size={20} />
                  )}
                </span>
                <ul
                  className={`${
                    showServices
                      ? "flex flex-col gap-y-2 items-start ml-4 my-4 text-sm capitalize list-disc"
                      : "hidden"
                  }`}
                >
                  {data?.map(({ slug, title }, index) => (
                    <li key={index}>
                      <Link
                        to={`/services/${slug}`}
                        onClick={closeDrawerHandler}
                        className="text-white text-start hover:underline"
                      >
                        {title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </button>
              <Link
                to={"/partner"}
                onClick={closeDrawerHandler}
                className="border-b py-2 text-white font-medium"
              >
                Partners
              </Link>
              <Link
                to={"/clients"}
                onClick={closeDrawerHandler}
                className="border-b py-2 text-white font-medium"
              >
                clients
              </Link>
              <Link
                to={"/awards"}
                onClick={closeDrawerHandler}
                className="border-b py-2 text-white font-medium"
              >
                awards
              </Link>

              <Link
                to={"/blog"}
                onClick={closeDrawerHandler}
                className="border-b py-2 text-white font-medium"
              >
                Blog
              </Link>
              <Link
                to={"/contact-us"}
                onClick={closeDrawerHandler}
                className="border-b py-2 text-white font-medium"
              >
                Contact us
              </Link>
            </ul>
          </div>
        </div>
        <div>
          <p className="uppercase text-white text-center pt-3 text-lg">
            Keep in touch{" "}
          </p>
          <a
            target="_blank"
            href="https://www.linkedin.com/company/nabs-integrated-technical-services/"
            className="flex items-center justify-center gap-x-4 text-white text-2xl mt-3"
            rel="noreferrer"
          >
            <LinkedinLogo size={32} />
          </a>
        </div>
        <div className="border-t border-white pb-8"></div>
      </div>
      <div
        onClick={closeDrawerHandler}
        className={`fixed inset-0 w-[100vw] h-[100vh] bg-[#000000ce] transition ease-in duration-300 ${
          drawerIsActive
            ? "opacity-100 z-[100] scale-1 select-auto visible"
            : "opacity-0 z-[-1] scale-[1.2] select-none invisible"
        }`}
      ></div>
    </header>
  );
};

export default Header;
