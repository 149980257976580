import bg from "assests/clients.jpg";
import Container from "Components/Container/Container";

import { useState, useEffect } from "react";
import IsLoading from "Components/RequestHandler/IsLoading";
import useFetch from "Hooks/useFetch";
import IsError from "Components/RequestHandler/IsError";

const Clients = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { fetchData } = useFetch();

  const getData = async () => {
    setIsLoading(true);
    setIsError(false);

    try {
      const data = await fetchData("api/clients");
      setData(data?.clients_data);
    } catch (error) {
      setIsError(true);
      // console.error("Failed to fetch home data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (isLoading) return <IsLoading />;
  if (isError) return <IsError />;
  if (data) {
    return (
      <div>
        <main className="partner-bg">
          <div
            className="fixed top-0 left-0 w-full h-full bg-cover bg-center z-0"
            style={{ backgroundImage: `url(${bg})` }}
          ></div>
          <div className="relative z-10 pt-[20vh]">
            <Container>
              <div className="mb-secondary">
                <h1 className="text-4xl lg:text-5xl font-black text-white">
                  Clients That Trust Us
                </h1>
              </div>
              <section className={"flex flex-col gap-y-16 mb-primary"}>
                {data?.map(({ category, logos }, index) => (
                  <div className="bg-white p-6 rounded-[28px]" key={index}>
                    <div className={"border-b pb-2"}>
                      <h6 className="text-primary text-xl font-bold">
                        {category}
                      </h6>
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-6">
                      {logos.map((image, index) => (
                        <div
                          className={"rounded-2xl border border-[#ccc] p-2"}
                          key={index}
                        >
                          <img className="rounded-xl" src={image} alt="" />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </section>
            </Container>
          </div>
        </main>
      </div>
    );
  }
};

export default Clients;
