import React from "react";
import logo from "assests/logo.png";
import { Link } from "react-router-dom";
import "./Logo.css";


const Logo = ({ isSmall }) => {
  return (
    <Link to={"/"}>
      <img
        className={isSmall ? "w-48" : "custom-width"} // Larger on desktop, smaller on mobile
        src={logo}
        alt="Logo"
      />
    </Link>
  );
};

export default Logo;
