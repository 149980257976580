import React from "react";
import Container from "Components/Container/Container";
import stripHtmlTags from "Utilities/stripHtmlTags";

const WhoWeAre = ({ data }) => {
  return (
    <div className="pb-10 lg:bg-primary">
      <Container>
        <div className="bg-white px-6 py-10 lg:px-10 lg:py-24 rounded-[52px] flex flex-col lg:flex-row gap-4 lg:gap-8">
          <div className="flex-1">
            <p>
              <span className="block  text-xl text-primary">
                {data?.sub_title}
              </span>
              <span className="text-3xl text-primary font-medium">
                {data?.title}
              </span>
            </p>
          </div>
          <div className="flex-[4] text-primary font-medium">
            <p
              className="mb-10 w-[85%]"
              dangerouslySetInnerHTML={{ __html: data?.text }}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default WhoWeAre;
