import React, { useEffect, useState, useRef } from "react";
import Container from "Components/Container/Container";
import Clients from "Pages/Home/Clients/Clients";
import { Link, useParams } from "react-router-dom";
import fixImageUrl from "Helpers/FixImageUrl";
import IsLoading from "Components/RequestHandler/IsLoading";
import IsError from "Components/RequestHandler/IsError";
import useFetch from "Hooks/useFetch";
import "./Services.css";

const Services = () => {
  const { slug } = useParams();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { fetchData } = useFetch();
  const [activeSection, setActiveSection] = useState(null);
  const sectionRefs = useRef([]);

  const getData = async () => {
    setIsLoading(true);
    setIsError(false);

    try {
      const data = await fetchData(`api/services/${slug}`);
      setData(data);
    } catch (error) {
      setIsError(true);
      console.error("Failed to fetch home data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [slug]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.getAttribute("data-index"));
          }
        });
      },
      { threshold: 0.5 } // Adjust threshold as needed
    );

    sectionRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      sectionRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, [data]);

  if (isLoading) return <IsLoading />;
  if (isError) return <IsError />;
  if (data) {
    return (
      <div>
        <main className="relative min-h-screen">
          <section
            style={{ backgroundImage: `url(${data?.image})` }}
            className="fixed top-0 left-0 w-full h-full bg-cover bg-center z-0"
          ></section>

          <div className="relative z-10 pt-[20vh]">
            <Container>
              <div className="mb-secondary">
                <h6 className="text-secondary mb-2 text-xl">Our Services</h6>
                <p className="font-black text-4xl lg:text-5xl text-white">
                  {data?.title}
                </p>
              </div>

              <section className="flex flex-col gap-y-0 lg:gap-y-24 mb-secondary">
                {data &&
                  data.service_data?.sub_services.map(
                    ({ title, text, image, inner_sub_services }, index) => {
                      const isEven = index % 2 === 0;

                      return (
                        <div
                          ref={(el) => (sectionRefs.current[index] = el)}
                          data-index={index}
                          className={`py-6 px-6 lg:py-16 lg:px-16 mobile-service-column relative rounded-[44px] overflow-hidden transition ease-in duration-500 ${
                            activeSection == index
                              ? "opacity-100 scale-1"
                              : "opacity-[0.4] scale-[0.9]"
                          }`}
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: isEven ? "row" : "row-reverse",
                            alignItems: "center",
                            backgroundColor: "#000000b5", // Ensuring the text is readable
                          }}
                        >
                          <div
                            className="flex-1"
                            style={{
                              height: "400px",
                              overflow: "hidden",
                              borderRadius: "44px",
                            }}
                          >
                            <img
                              src={fixImageUrl(image)}
                              alt={title}
                              className="w-full h-full object-cover "
                              style={{ borderRadius: "44px" }}
                            />
                          </div>

                          <div className="flex-1 p-6 lg:p-12 text-white">
                            <h4 className="text-2xl md:text-2xl lg:text-3xl font-bold mb-5">
                              {title}
                            </h4>
                            <div
                              className="md:w-1 lg:w-[90%]"
                              dangerouslySetInnerHTML={{ __html: text }} // Render HTML content directly
                            />

                            <div className="grid ss:grid-cols-2 lg:grid-cols-2 gap-x-6 gap-y-4 mt-10">
                              {inner_sub_services.map(
                                ({ title, slug: innerSlug }, subIndex) => (
                                  <Link
                                    state={{
                                      data: slug,
                                    }}
                                    to={`#`}
                                    className="block px-4 py-2 border border-[#84a9c5] rounded-2xl bg-[#063c67]"
                                    key={subIndex}
                                    style={{ cursor: "unset" }}
                                  >
                                    <p className="text-start text-xs">
                                      {title}
                                    </p>
                                  </Link>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
              </section>
            </Container>
          </div>
        </main>

        <Clients data={data?.clients} />
      </div>
    );
  }
};

export default Services;
