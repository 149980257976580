import React, { useState, useEffect } from "react";

const Counter = ({ endValue, duration, sectionId }) => {
  const [count, setCount] = useState(0);
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const section = document.getElementById(sectionId);

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true); // Trigger the counting
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the section is visible
    );

    if (section) {
      observer.observe(section);
    }

    return () => {
      if (section) {
        observer.unobserve(section);
      }
    };
  }, [sectionId]);

  useEffect(() => {
    if (isInView) {
      let start = 0;
      const increment = endValue / (duration / 10); // Calculate the increment per interval
      const interval = setInterval(() => {
        start += increment;
        if (start >= endValue) {
          clearInterval(interval);
          setCount(endValue); // Ensure final value is set correctly
        } else {
          setCount(Math.ceil(start)); // Update the count
        }
      }, 10); // Run every 10 milliseconds

      return () => clearInterval(interval); // Cleanup interval on component unmount
    }
  }, [isInView, endValue, duration]);

  return <p className="text-4xl font-bold">{count}+</p>;
};

export default Counter;
