import React, { useState } from "react";
import "./mobileButtonStyle.css";

const NavigationMenu = ({ openDrawerHandler }) => {
  const [active, setActive] = useState(false);

  const handleMobileNav = () => {
    setActive((cur) => !cur);
    openDrawerHandler();
  };
  return (
    <div>
      {" "}
      <button
        onClick={openDrawerHandler}
        className={`relative z-[100] MobileBtnBox flex flex-col gap-y-1.5 ${
          active ? "isActive" : ""
        }`}
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
    </div>
  );
};

export default NavigationMenu;
