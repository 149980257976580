import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import IsLoading from "Components/RequestHandler/IsLoading";
import IsError from "Components/RequestHandler/IsError";
import useFetch from "Hooks/useFetch";
import Container from "Components/Container/Container";

const BlogDetailed = () => {
  const { slug } = useParams();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { fetchData } = useFetch();

  const getData = async () => {
    setIsLoading(true);
    setIsError(false);

    try {
      const data = await fetchData(`api/blogs/${slug}`);
      setData(data?.blogdata);
    } catch (error) {
      setIsError(true);
      console.error("Failed to fetch home data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [slug]);

  if (isLoading) return <IsLoading />;
  if (isError) return <IsError />;
  if (data) {
    return (
      <main className="py-[20vh]">
        <Container>
          <div className="w-full">
            <img
              className="w-full max-h-[600px] object-cover "
              src={data?.image}
              alt=""
            />
          </div>

          <div className="mt-8 text-white lg:w-3/4 lg:mx-auto">
            <h2 className="text-2xl capitalize">{data?.title}</h2>

            <div
              className="mt-4 "
              dangerouslySetInnerHTML={{ __html: data?.description }} // Render HTML content directly
            />
          </div>
        </Container>
      </main>
    );
  }
};

export default BlogDetailed;
