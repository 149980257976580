import Container from "Components/Container/Container";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "./style.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Navigation, Keyboard, Autoplay } from "swiper/modules";

const Clients = ({ data }) => {
  return (
    <section className="bg-white relative z-[100] py-24 overflow-hidden">
      <Container>
        <h2 className="text-secondary text-3xl font-[300] text-center">
          Clients That Trust Us
        </h2>

        <Swiper
          slidesPerView={1}
          slidesPerGroup={1}
          spaceBetween={30}
          keyboard={{
            enabled: true,
          }}
          navigation={false}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          speed={800} // Transition speed between slides (1 second)
          modules={[Keyboard, Navigation, Autoplay]} // Removed Pagination module
          className="mt-10 client-slider"
          breakpoints={{
            // For smaller screens, reduce the number of slides visible
            320: {
              slidesPerView: 2,
              slidesPerGroup: 1,
            },
            578: {
              slidesPerView: 3,
              slidesPerGroup: 1,
            },
            992: {
              slidesPerView: 5,
              slidesPerGroup: 1,
            },
          }}
        >
          {data?.map(({ logos }, index) => (
            <SwiperSlide
              key={index}
              className="flex items-center justify-center p-4 rounded-xl overflow-hidden border border-gray-500"
            >
              <div className="opacity-[15%] absolute top-0 left-0 w-full h-full z-[1]"></div>
              <img
                className="mx-auto relative z-[10] rounded-xl aspect-[5/3] "
                src={logos}
                alt={`Client logo ${index + 1}`}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <Link
          className="text-center block mt-8 hover:underline"
          to={"/clients"}
        >
          View All
        </Link>
      </Container>
    </section>
  );
};

export default Clients;
