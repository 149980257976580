import Container from "Components/Container/Container";
import React from "react";
import stripHtmlTags from "Utilities/stripHtmlTags";

const Values = ({ data }) => {
  return (
    <section className="my-secondary lg:mb-0">
      <Container>
        <div className="flex flex-col lg:flex-row gap-10">
          {data?.map(({ image, sub_title, title }, index) => (
            <div
              className="flex-1 value-bg pt-[22rem] w-[95%] pb-14 px-8 lg:px-12 rounded-xl relative overflow-hidden"
              key={index}
            >
              <div className="absolute top-0 left-0 w-full h-full z-[0] overflow-hidden">
                <img
                  src={image}
                  className="rounded-xl w-full h-full object-cover transition ease-in duration-500 hover:scale-[1.1] object-top"
                  alt=""
                />
              </div>
              <h4 className="text-3xl font-bold text-white mb-2 relative z-[10]">
                {title}
              </h4>
              <p className="text-white font-medium pr-4 relative z-[10]">
                {stripHtmlTags(sub_title)}
              </p>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default Values;
