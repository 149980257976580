const useFetch = () => {
  const fetchData = async (url) => {
    try {
      const response = await fetch(`https://www.nabs-its.com/${url}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Fetch error:", error);
      throw error;
    }
  };

  return { fetchData };
};

export default useFetch;
