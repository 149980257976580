export const footerLinkNav = [
  {
    text: "Home",
    link: "/",
  },
  {
    text: "About us",
    link: "/about-us",
  },
  {
    text: "Partners",
    link: "/partner",
  },
  {
    text: "Clients",
    link: "/clients",
  },
  {
    text: "Awards",
    link: "/awards",
  },
  {
    text: "Blog",
    link: "/blog",
  },
];

export const footerServices = {
  title: "Services",
  list: [
    {
      text: "Information Technology",
      link: "/services",
    },
    {
      text: "Datacenter Technology",
      link: "/services",
    },
    {
      text: "Operational Technology",
      link: "/services",
    },
    {
      text: "Automation Technology",
      link: "/services",
    },
    {
      text: "Professional Services",
      link: "/services",
    },
  ],
};

export const footerSolution = {
  title: "Solutions",
  list: [
    {
      text: "Network Solutions",
      link: "",
    },
    {
      text: "Unified Communications &  Collaboration",
      link: "",
    },
    {
      text: "Complex Communications Solutions",
      link: "",
    },
    {
      text: "Data Center Solutions",
      link: "",
    },
    {
      text: "Structured Cabling Systems",
      link: "",
    },
  ],
};

export const footerContact = {
  title: "Contact us",
  location: [
    "NABS Integrated Technical Services",
    "Abu Dhabi | Dubai | Dubai Financial Center | Saudi Arabia",
  ],

  phone: ["+971 2 6216618", "+971 4 2522963", "+966 5 04831827"],
  email: "info@nabs-its.com",
};
