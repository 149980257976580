import { useContext } from "react";
import bg from "assests/home/services/b1.jpg";
import "./Slider.css";
import Container from "Components/Container/Container";
import { Link } from "react-router-dom"; // Import the Link component
import { ServicesContext } from "Context/ServicesContext";

const ServicesSlider = () => {
  const { data } = useContext(ServicesContext);

  const extractListItems = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const listItems = doc.querySelectorAll("li");
    return Array.from(listItems).map((item) => item.textContent);
  };

  return (
    <div
      className="relative bg-cover bg-center py-8"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <Container>
        <h2 className="font-[100] text-3xl ss:text-4xl text-white text-center mb-6">
          Our Global Service Offerings
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {data?.map(({ num, title, ul, slug, image }, index) => {
            const listItems = extractListItems(ul);
            return (
              <div
                key={index}
                className="service-item"
                style={{ backgroundImage: `url(${image})` }}
              >
                {/* Title positioned at the bottom */}
                <div className="service-info text-white">
                  <p className="text-3xl font-bold">{title}</p>
                </div>

                {/* Hidden list shown on hover */}
                <ul>
                  {listItems.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>

                {/* Read More Button hidden initially and shown on hover */}
                <div className="read-more-button">
                  <Link to={`/services/${slug}`} className="text-white bg-black px-4 py-2 inline-block rounded">
                    Read More
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
};

export default ServicesSlider;
