import { Routes, Route } from "react-router-dom";
import ScrollToTop from "Hooks/ScrollToTop";
import ContextProvider from "Context/ContextProvider";
// layout
import Header from "layout/Header/Header";
import Footer from "layout/Footer/Footer";

import HomePage from "Pages/Home/Home";
import About from "Pages/About/About";
import Partner from "Pages/Partner/Partner";
import Services from "Pages/Services/Services";
import Awards from "Pages/Awards/Awards";
import Clients from "Pages/Clients/Clients";
import Contact from "Pages/Contact/Contact";
import InnerServices from "Pages/Services/InnerServices";
import BlogDetailed from "Pages/Blog/BlogDetailed";
import Blog from "Pages/Blog/Blog.jsx";
const App = () => {
  return (
    <div className="App bg-primary">
      <ScrollToTop />
      <ContextProvider>
        <Header />
        <Routes>
          <Route index element={<HomePage />} />
          <Route path="about-us" element={<About />} />
          <Route path="partner" element={<Partner />} />
          <Route path="services/:slug" element={<Services />} />
          <Route path="blog/:slug" element={<BlogDetailed />} />
          <Route path="blog" element={<Blog />} />
          <Route path="inner-services/:slug" element={<InnerServices />} />
          <Route path="awards" element={<Awards />} />
          <Route path="clients" element={<Clients />} />
          <Route path="contact-us" element={<Contact />} />
        </Routes>
        <Footer />
      </ContextProvider>
    </div>
  );
};

export default App;
