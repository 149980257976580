import { useState, useEffect } from "react";
import Container from "Components/Container/Container";
import bg from "assests/awards.jpg";
import Clients from "Pages/Home/Clients/Clients";
import Blog from "Pages/Home/Blog/Blog";
import useFetch from "Hooks/useFetch";
import IsLoading from "Components/RequestHandler/IsLoading";
import IsError from "Components/RequestHandler/IsError";
const Awards = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { fetchData } = useFetch();

  const getData = async () => {
    setIsLoading(true);
    setIsError(false);

    try {
      const data = await fetchData("api/awards");
      setData(data);
    } catch (error) {
      setIsError(true);
      // console.error("Failed to fetch home data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (isLoading) return <IsLoading />;
  if (isError) return <IsError />;
  if (data) {
    return (
      <div>
        <main className="partner-bg">
          <div
            className="fixed top-0 left-0 w-full h-full bg-cover bg-center z-0"
            style={{ backgroundImage: `url(${bg})` }}
          ></div>
          <div className="relative z-10 pt-[20vh]">
            <Container>
              <section className="mb-secondary">
                <div className="lg:w-3/4 xl:w-1/2">
                  <h1 className="text-3xl ss:text-4xl lg:text-5xl font-black text-white">
                    Award & Recognition and Certificates
                  </h1>
                </div>
              </section>

              <section className=" grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-10 gap-y-10 lg:gap-y-24 bg-white px-12 lg:px-20 py-14 rounded-[44px] mb-primary">
                {data?.awards_data?.map(({ logos, text }, index) => (
                  <div key={index} className="flex flex-col items-center">
                    <img
                      className="w-40 border rounded-full border-[#ccc] p-3"
                      src={logos}
                      alt=""
                    />
                    <p className="font-medium pt-2 text-lg w-3/4 mx-auto text-center">
                      {text}
                    </p>
                  </div>
                ))}
              </section>
            </Container>
          </div>
          <Clients data={data?.clients} />
          <Blog />
        </main>
      </div>
    );
  }
};

export default Awards;
