import { useContext } from "react";
import { PartnerContext } from "Context/PartnerContext";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay"; // Import Autoplay styles
import { Pagination, Navigation, Keyboard, Autoplay } from "swiper/modules"; // Import Autoplay module
import "./style.css";

const PartnerSlider = () => {
  const { data } = useContext(PartnerContext);

  return (
    <div className="pt-section text-white">
      <Swiper
        slidesPerView={2} // Show 1 slide at a time initially
        slidesPerGroup={1} // Move 1 slide per group (transition)
        breakpoints={{
          420: {
            slidesPerView: 2, // Show 2 slides at once on small screens
            slidesPerGroup: 1, // Move 1 slide at a time
          },
          578: {
            slidesPerView: 3, // Show 3 slides at once
            slidesPerGroup: 1, // Move 1 slide at a time
          },
          992: {
            slidesPerView: 5, // Show 5 slides at once
            slidesPerGroup: 1, // Move 1 slide at a time
          },
          1200: {
            slidesPerView: 6, // Show 6 slides at once on large screens
            slidesPerGroup: 1, // Move 1 slide at a time
          },
        }}
        spaceBetween={30} // Space between slides
        pagination={{
          clickable: false,
        }}
        keyboard={{
          enabled: true,
        }}
        navigation={false}
        speed={800} // Slide transition speed (1 second)
        autoplay={{
          delay: 2000, // Delay between slides (3 seconds)
          disableOnInteraction: false, // Continue autoplay after interaction
        }}
        modules={[Keyboard, Pagination, Navigation, Autoplay]} // Added Autoplay module
        className="partner-slider"
      >
        {data?.map((img, index) => (
          <SwiperSlide
            key={index}
            className="flex items-center justify-center p-3 rounded-xl overflow-hidden bg-primary border border-gray-500"
          >
            <div className="bg-secondary opacity-[15%] absolute top-0 left-0 w-full h-full z-[1]"></div>
            <img
              className="mx-auto relative z-[10] object-contai rounded-xl aspect-[5/3]"
              src={img}
              alt=""
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default PartnerSlider;
