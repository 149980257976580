import React from "react";
import { footerContact } from "Constant/FooterContent";
const FooterContact = () => {
  return (
    <div className="font-nato">
      <p className="uppercase text-secondary mb-4">{footerContact.title}</p>
      <div className="text-white flex flex-col gap-y-2 mb-2 ">
        {footerContact.location.map((text, index) => (
          <p key={index}>{text}</p>
        ))}
      </div>
      <div className="text-white flex gap-x-2 ">
        <p>Phone:</p>
        <div className="flex flex-col ">
          {footerContact.phone.map((num, index) => (
            <a
              key={index}
              target="_blank"
              rel="noreferrer"
              className="hover:underline"
              href={`tel:${num}`}
            >
              {num}
            </a>
          ))}
        </div>
      </div>
      <div className="text-white flex gap-x-2 mt-2 ">
        <p>Email:</p>
        <div className="flex flex-col ">
          <a
            target="_blank"
            rel="noreferrer"
            className="hover:underline"
            href={`mailto:${footerContact.email}`}
          >
            {footerContact.email}
          </a>
        </div>
      </div>
    </div>
  );
};

export default FooterContact;
